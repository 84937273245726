












































































































































































import { ValidationProvider, ValidationObserver } from "vee-validate";
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import Brand from "@/view/layout/brand/Brand.vue";
import OtpInput from "@/view/content/oneTimePassword/OtpInput.vue";
import Inputmask from "inputmask";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/ru";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);
//validation rules
import { configure } from "vee-validate";

import { authModule, errorModule } from "@/store";

configure({
  classes: {
    valid: "has-success",
    invalid: "has-error",
  },
});

import { extend } from "vee-validate";
import { required, regex } from "vee-validate/dist/rules";
extend("required", required);
extend("regex", regex);
extend("birthday", {
  message: "некорректная дата",
  validate: (value, args) => { return (dayjs(value, "DD.MM.YYYY").format("YYYY-MM-DD") !== "Invalid Date"); }, 
  });

import { localize } from "vee-validate";
import ru from "vee-validate/dist/locale/ru.json";

localize("ru", ru);

localize({
  en: {
    names: {
      Login: "Login",
      Password: "Password",
      BirthDay: "BirthDay",
      RulesAccepted: "RulesAccepted",
    },
  },
  ru: {
    names: {
      Login: "Логин",
      Password: "Пароль",
      BirthDay: "Дата рождения",
      RulesAccepted: "Условия",
    },
    fields: {
      Login: {
        required: "введите номер телефона",
        regex: "некорректный формат телефона",
      },
      BirthDay: {
        required: "введите дату рождения",
        birthday: "некорректная дата",
      },
      RulesAccepted: {
        required: "нужно принять",
      },
    },
  },
});

@Component({
  components: {
    Brand,
    OtpInput,
    ValidationProvider,
    ValidationObserver,
    DatePicker,
  },
})
export default class Login extends Vue {
  @Prop({ default: "" })
  token?: string;

  login = "";
  birthDay = "";
  acceptRules = false;
  rememberMe = true;

  open = false;

  mounted() {
    if (authModule.state.isAuthenticated) {
      this.$router.push({ name: "home" });
      return;
    }

    if(this.token != null && this.token.length){
      authModule.actions.loginbytoken({ token: this.token! }).then(() => {
        this.$router.push({ name: "home" });
      })
    }
    const im = new Inputmask("+7 (999) 999 9999", {
      placeholder: "*",
      inputmode: "numeric",
    });
    im.mask(this.$refs.phone as HTMLElement);
    this.focusPhoneNumber();

    this.configureDatepicker();
  }

  private configureDatepicker() {
    const input = this.$refs.birthday as HTMLElement;
    const birthday = new Inputmask("99.99.9999", {
      placeholder: "__.__.____",
      inputmode: "numeric",
    });
    birthday.mask(input);
  }

  private focusPhoneNumber() {
    const phoneInput = this.$refs.phone as HTMLInputElement;
    phoneInput.focus();
    phoneInput.click();
    phoneInput.select();
  }

  get errors() {
    return errorModule.state.errors;
  }

  get smsSended() {
    return authModule.getters.smsSended;
  }

  get loading() {
    return authModule.state.loading;
  }

  async showTerms() {
    const htmText = await fetch("/terms.html").then((x) => x.text());
    Swal.fire({
      html: htmText,
      heightAuto: false,
      confirmButtonText: "Прочитал",
      customClass: { htmlContainer: "text-left", confirmButton: "w-50" },
    });
  }

  handleClick(evt: any) {
    if (evt.target.tagName !== "INPUT") {
      this.open = true;
    }
  }

  getSms(): void {
    authModule.actions
      .getTempPassword({
        phone: this.login,
        birthDay: dayjs(this.birthDay, "DD.MM.YYYY").format("YYYY-MM-DD"),
        token: this.token!,
        rememberMe: this.rememberMe,
      })
      .then(() => {
        this.$router.push({ name: "enterpassword" });
      })
      .catch(() => {
        console.log("ошибка получения одноразового ароля");
      });
  }

  @Watch("errors")
  errorRised() {
    if (this.errors == null || this.errors.every(x => x.Value === '' || x.Value == null)) return;
    Swal.fire({
      title: "Ошибка",
      text: this.errors.map(x => x.Value).join("<br/>"),
      icon: "warning",
      heightAuto: false,
    });
  }
}
